import React, { useState, useEffect } from 'react';
import './ActivityEditor.css'; // Importer CSS-filen

const ActivityEditor = ({ initialData = {}, onSubmit }) => {
  const [formData, setFormData] = useState({
    activityNumber: initialData.orderData?.activityNumber || '',
    hubCaseNumber: initialData.caseNumber || '',
    guid: initialData.guid || '',  
    customerName: initialData.customer?.name || '',
    customerAddress: initialData.customer?.address || '',
    customerPostalCode: initialData.customer?.postalCode || '',
    customerCity: initialData.customer?.city || '',
    customerEmail: initialData.customer?.email || '',
    customerPhone: initialData.customer?.phone || '',
    productSerial: initialData.productData?.serial || '',
    productImei: initialData.productData?.imei || '',
    productModel: initialData.productData?.model || '',
    productBrand: initialData.manufacturer?.name || '',
    productSerialNew: initialData.productData?.serialNew || '',
    productImeiNew: initialData.productData?.imeiNew || '',
    problemText: initialData.productData?.problemText || '',
    solution: initialData.productData?.solution || '',
    purchaseDate: initialData.productData?.purchaseDate || '',  // Nytt felt for purchaseDate
    serviceCode: initialData.orderData?.providerSpecific?.elcare?.serviceCode || '',
    gref: initialData.orderData?.providerSpecific?.elcare?.gref || '',
    spec: initialData.orderData?.providerSpecific?.elcare?.spec || '',
    createdAt: initialData.createdAt || '',
    serviceStatus: initialData.currentStatus || ''
  });

  useEffect(() => {
    // Når initialData oppdateres, aktiver fade-effekten
    const wrapper = document.querySelector('.outer-wrapper');
    const gridItems = document.querySelectorAll('.grid-item');
    const inputs = document.querySelectorAll('.input-group input, .input-group textarea');
    
    // Legg til fade-in klassen
    wrapper.classList.add('fade-in');
    gridItems.forEach(item => item.classList.add('fade-in'));
    inputs.forEach(input => input.classList.add('fade-in'));

    // Fjern fade-in etter 300ms (match overgangsvarigheten)
    setTimeout(() => {
      wrapper.classList.remove('fade-in');
      gridItems.forEach(item => item.classList.remove('fade-in'));
      inputs.forEach(input => input.classList.remove('fade-in'));
    }, 300);

    // Oppdater formData med ny initialData
    setFormData({
      activityNumber: initialData.orderData?.activityNumber || '',
      hubCaseNumber: initialData.caseNumber || '',
      guid: initialData.guid || '',
      customerName: initialData.customer?.name || '',
      customerAddress: initialData.customer?.address || '',
      customerPostalCode: initialData.customer?.postalCode || '',
      customerCity: initialData.customer?.city || '',
      customerEmail: initialData.customer?.email || '',
      customerPhone: initialData.customer?.phone || '',
      productSerial: initialData.productData?.serial || '',
      productImei: initialData.productData?.imei || '',
      productModel: initialData.productData?.model || '',
      productBrand: initialData.manufacturer?.name || '',
      productSerialNew: initialData.productData?.serialNew || '',
      productImeiNew: initialData.productData?.imeiNew || '',
      problemText: initialData.productData?.problemText || '',
      solution: initialData.productData?.solution || '',
      purchaseDate: initialData.productData?.purchaseDate || '',
      serviceCode: initialData.orderData?.providerSpecific?.elcare?.serviceCode || '',
      gref: initialData.orderData?.providerSpecific?.elcare?.gref || '',
      spec: initialData.orderData?.providerSpecific?.elcare?.spec || '',
      createdAt: initialData.createdAt || '',
      serviceStatus: initialData.currentStatus || ''
    });
  }, [initialData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(formData);  // Send formData til onSubmit-funksjonen
    }
  };

  return (
    <div className="outer-wrapper">
      <div className="grid-container">
        {/* Act Number, Hub Case Number og GUID */}
        <div className="grid-item">
          <div className="input-group">
            <label>Activity Number</label>
            <input
              className="input"
              type="text"
              name="activityNumber"
              value={formData.activityNumber}
              disabled // Gjør feltet lesbart, men ikke redigerbart
            />
          </div>
        </div>
        <div className="grid-item">
          <div className="input-group">
            <label>Hub Case Number</label>
            <input
              className="input"
              type="text"
              name="hubCaseNumber"
              value={formData.hubCaseNumber}
              disabled // Gjør feltet lesbart, men ikke redigerbart
            />
          </div>
          <div className="input-group">
            <label>GUID</label>
            <input
              className="input"
              type="text"
              name="guid"
              value={formData.guid}
              disabled // Gjør feltet lesbart, men ikke redigerbart
            />
          </div>
        </div>

        {/* Customer Information */}
        <div className="grid-item">
          <h3>Customer Information</h3>
          <div className="input-group">
            <label>Customer Name</label>
            <input
              className="input"
              type="text"
              name="customerName"
              value={formData.customerName}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-group">
            <label>Address</label>
            <input
              className="input"
              type="text"
              name="customerAddress"
              value={formData.customerAddress}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-group">
            <label>Postal Code</label>
            <input
              className="input"
              type="text"
              name="customerPostalCode"
              value={formData.customerPostalCode}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-group">
            <label>City</label>
            <input
              className="input"
              type="text"
              name="customerCity"
              value={formData.customerCity}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-group">
            <label>Email</label>
            <input
              className="input"
              type="email"
              name="customerEmail"
              value={formData.customerEmail}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-group">
            <label>Phone Number</label>
            <input
              className="input"
              type="tel"
              name="customerPhone"
              value={formData.customerPhone}
              onChange={handleInputChange}
            />
          </div>
        </div>

        {/* Unit Information */}
        <div className="grid-item">
          <h3>Unit Information</h3>
          <div className="input-group">
            <label>Serial Number</label>
            <input
              className="input"
              type="text"
              name="productSerial"
              value={formData.productSerial}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-group">
            <label>Serial Number (New)</label>
            <input
              className="input"
              type="text"
              name="productSerialNew"
              value={formData.productSerialNew}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-group">
            <label>Model</label>
            <input
              className="input"
              type="text"
              name="productModel"
              value={formData.productModel}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-group">
            <label>Brand</label>
            <input
              className="input"
              type="text"
              name="productBrand"
              value={formData.productBrand}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-group">
            <label>IMEI</label>
            <input
              className="input"
              type="text"
              name="productImei"
              value={formData.productImei}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-group">
            <label>Purchase Date</label>
            <input
              className="input"
              type="date"
              name="purchaseDate"
              value={formData.purchaseDate}
              onChange={handleInputChange}
            />
          </div>
        </div>

        {/* Service Information */}
        <div className="grid-item">
          <h3>Service Information</h3>
          <div className="input-group">
            <label>Service Code</label>
            <input
              className="input"
              type="text"
              name="serviceCode"
              value={formData.serviceCode}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-group">
            <label>Gref</label>
            <input
              className="input"
              type="text"
              name="gref"
              value={formData.gref}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-group">
            <label>Spec</label>
            <input
              className="input"
              type="text"
              name="spec"
              value={formData.spec}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-group">
            <label>Date Received</label>
            <input
              className="input"
              type="date"
              name="createdAt" // Bruk "date" input type for redigerbar dato
              value={formData.createdAt ? formData.createdAt.split('T')[0] : ''}
              onChange={handleInputChange} // Tillater redigering
            />
          </div>
          <div className="input-group">
            <label>Service Status</label>
            <input
              className="input"
              type="text"
              name="serviceStatus"
              value={formData.serviceStatus}
              onChange={handleInputChange} // Tillater redigering
            />
          </div>
        </div>

        {/* Problem and Solution */}
        <div className="grid-item">
          <h3>Problem and Solution</h3>
          <div className="input-group">
            <label>Problem Description</label>
            <textarea
              className="input"
              name="problemText"
              value={formData.problemText}
              onChange={handleInputChange}
              rows="4"
            />
          </div>
          <div className="input-group">
            <label>Solution</label>
            <textarea
              className="input"
              name="solution"
              value={formData.solution}
              onChange={handleInputChange}
              rows="2"
            />
          </div>
        </div>
      </div>

      <button className="submit-button" onClick={handleSubmit}>
        Save Changes
      </button>
    </div>
  );
};

export default ActivityEditor;

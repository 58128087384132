import React, { useState, useEffect } from 'react';
import { TableHead, TableRow, TableCell, Checkbox, TextField } from '@mui/material';
import { FixedSizeList as List } from 'react-window';

function BomListManagerTable({ columns, rows }) {
  const [filterText, setFilterText] = useState(''); // State for søketekst
  const [filteredRows, setFilteredRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]); // State for å holde oversikt over valgte rader

  // Funksjon for å filtrere rader basert på søketeksten
  const filterRows = (rows, filter) => {
    if (!filter) return rows;

    const filterTerms = filter.split('+').map(term => term.trim().toLowerCase());

    return rows.filter(row =>
      filterTerms.some(term =>
        columns.some(col =>
          row[col.field]?.toLowerCase().includes(term)
        )
      )
    );
  };

  // Funksjon for å håndtere valg av rader
  const handleRowSelect = (rowIndex) => {
    setSelectedRows(prevSelected =>
      prevSelected.includes(rowIndex)
        ? prevSelected.filter(index => index !== rowIndex)
        : [...prevSelected, rowIndex]
    );
  };

  // Funksjon for å sortere rader basert på "Price"-kolonnen
  const sortRowsByPrice = (rows) => {
    return [...rows].sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
  };

  // Oppdater filtrerte rader når filteret endres
  useEffect(() => {
    const validFilter = filterText.trim().toLowerCase();
    const filtered = filterRows(rows, validFilter);
    setFilteredRows(sortRowsByPrice(filtered));
  }, [filterText, rows]);

  // Stiljusteringer for tabellen
  const styles = {
    tableContainer: {
      width: '100%',
      overflowX: 'auto',  // Tillater horisontal scrolling ved behov
    },
    tableCell: {
      padding: '6px 8px',
      fontSize: '0.85rem',
      whiteSpace: 'nowrap',      // Ingen linjeskift
      overflow: 'hidden',        // Skjuler innhold som er for langt
      textOverflow: 'ellipsis',  // Legger til "..." når teksten er for lang
      textAlign: 'left',         // Juster tekst til venstre
    },
    selectCell: {
      padding: '4px',
      width: '50px',
      minWidth: '50px',
    },
    tableRow: {
      height: '40px', // Øk høyden for å få mer plass til hver rad
    },
    selectedRow: {
      backgroundColor: '#8c2e10',
    },
    searchField: {
      marginBottom: '10px',
    },
    scrollbar: {
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        background: '#333',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: '4px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    },
  };

  // Renderer en rad for FixedSizeList
  const Row = ({ index, style }) => {
    const row = filteredRows[index];
    return (
      <TableRow
        key={index}
        style={{
          ...styles.tableRow,
          ...(selectedRows.includes(index) ? styles.selectedRow : {}),
          ...style, // Stilene som brukes av react-window
        }}
      >
        <TableCell style={styles.selectCell}>
          <Checkbox
            checked={selectedRows.includes(index)}
            onChange={() => handleRowSelect(index)}
          />
        </TableCell>
        {columns.map((column, colIndex) => (
          <TableCell
            key={colIndex}
            style={{
              ...styles.tableCell,
              width: column.field === 'description' ? '350px' : '100px',  // Sett bredde for "Description" og andre kolonner
              minWidth: column.field === 'description' ? '250px' : '80px', // Minimum bredde
            }}
          >
            {row[column.field]}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  return (
    <div style={styles.tableContainer}>
      <TextField
        label="Search"
        variant="outlined"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        style={styles.searchField}
        size="small"
      />
      <TableHead>
        <TableRow>
          <TableCell style={styles.selectCell}>Select</TableCell>
          {columns.map((column, index) => (
            <TableCell
              key={index}
              style={{
                ...styles.tableCell,
                width: column.field === 'description' ? '350px' : '100px', // Sett bredde for "Description" og andre kolonner
                minWidth: column.field === 'description' ? '250px' : '80px', // Minimum bredde
              }}
            >
              {column.headerName}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <List
        height={200}
        itemCount={filteredRows.length}
        itemSize={40} // Øk høyden på hver rad for bedre lesbarhet
        width="100%" // Sikrer at listen tar hele bredden
        className={styles.scrollbar}
        style={{ width: '100%', boxSizing: 'border-box' }} // Sørg for at 'List' tar full bredde
      >
        {Row}
      </List>
    </div>
  );
}

export default BomListManagerTable;

import React, { useState, useEffect, useRef } from 'react';
import { List, Box } from '@mui/material';
import ActivityListElement from './ActivityListElement';

const ActivityList = ({ activities, onSelectActivity, searchForDevice }) => {
  const [movingIndex, setMovingIndex] = useState(null);
  const [localActivities, setLocalActivities] = useState(activities);
  const listRef = useRef(null);

  // Oppdater localActivities når activities-proppen endrer seg (etter søk)
  useEffect(() => {
    setLocalActivities(activities);
  }, [activities]);

  const handleSelectActivity = (activity, index) => {
    const updatedActivities = [activity, ...localActivities.filter((_, i) => i !== index)];
    setLocalActivities(updatedActivities);
    setMovingIndex(index);
    onSelectActivity(activity);

    setTimeout(() => {
      setMovingIndex(null);
    }, 100);
  };

  const getTransitionDuration = (index) => {
    const element = listRef.current?.children[index];
    if (!element) return 0.3;

    const elementRect = element.getBoundingClientRect();
    const listRect = listRef.current.getBoundingClientRect();
    const distanceFromTop = elementRect.top - listRect.top;

    const maxDuration = 0.3;
    const proportionalDuration = Math.min(maxDuration, distanceFromTop / 500);

    return proportionalDuration;
  };

  return (
    <Box ref={listRef} sx={{ width: '100%', height: '100%', overflowY: 'auto' }}>
      <List sx={{ width: '100%' }}>
        {localActivities.map((activity, index) => (
          <ActivityListElement
            key={index}
            activity={activity}
            searchForDevice={searchForDevice}
            onClick={() => handleSelectActivity(activity, index)}
            isMoving={index === movingIndex}
            isTop={index === 0}
            transitionDuration={getTransitionDuration(index)}
          />
        ))}
      </List>
    </Box>
  );
};

export default ActivityList;

import React, { useEffect, useState, useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { jwtDecode } from 'jwt-decode'; 
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';  // Importer js-cookie for å håndtere cookies
import { AuthContext } from './AuthContext';  // Importer AuthContext

function ServerStatus() {
  const [serverStatus, setServerStatus] = useState('Checking...');
  const [isServerUp, setIsServerUp] = useState(null);
  const [isManualCheck, setIsManualCheck] = useState(false);
  const navigate = useNavigate();
  
  const { setIsAuthenticated, setRole } = useContext(AuthContext);  // Hent metoder fra AuthContext

  // Funksjon for å logge ut brukeren og fjerne token og rolle
  const logout = () => {
    Cookies.remove('token');
    Cookies.remove('role');
    setIsAuthenticated(false);  // Oppdater state i AuthContext
    setRole(null);  // Fjern rolle fra AuthContext
    navigate('/login');  // Naviger til login-siden
  };

  // Funksjon for å sjekke serverstatus via en ping til backend
  const checkServerStatus = async (isManual = false) => {
    if (isManual) {
      setServerStatus('Checking...');
    }

    try {
      const dashboardapi = process.env.REACT_APP_STATUS_API_URL;
      const response = await fetch(`${dashboardapi}/ping`);

      if (response.ok) {
        setTimeout(() => {
          setServerStatus('Backend: Yes');
          setIsServerUp(true);
        }, isManual ? 1000 : 0);  // 1 sekund forsinkelse hvis sjekken er manuell
      } else {
        setTimeout(() => {
          setServerStatus('Backend: No');
          setIsServerUp(false);
        }, isManual ? 1000 : 0);
      }
    } catch (error) {
      console.error('Error checking server status:', error);
      setTimeout(() => {
        setServerStatus('Backend: No');
        setIsServerUp(false);
      }, isManual ? 1000 : 0);
    }
  };

  // Funksjon for å sjekke om token er utløpt
  const isTokenExpired = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;  // Nåværende tid i sekunder
      return decodedToken.exp < currentTime;  // Returner true hvis tokenet er utløpt
    } catch (error) {
      console.error('Feil ved dekoding av token:', error);
      return true;  // Hvis det er feil, anta at tokenet er utløpt
    }
  };

  // Funksjon for å sjekke om brukeren er frosset
  const checkIfUserIsFrozen = async () => {
    const apiUrl = process.env.REACT_APP_STATUS_API_URL;
    const token = Cookies.get('token'); // Hent token fra cookies

    if (!token) {
        console.error('Ingen token funnet');
        logout();
        return;
    }

    // Sjekk om token er utløpt
    if (isTokenExpired(token)) {
        alert('Session expired, please log in again.');
        logout(); // Logg ut hvis token er utløpt
        return;
    }

    try {
        const decodedToken = jwtDecode(token);
        const username = decodedToken.sub; // Hent brukernavn (sub) fra tokenet

        const response = await fetch(`${apiUrl}/check_freeze`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ username }),
        });

        if (response.ok) {
            const data = await response.json();
            if (data.status === "success" && data.user && data.user.freeze === true) {
                // Vis alert først, så logg ut
                alert('Account has been frozen by admin');

                // Etter alert, utfør logout
                setTimeout(() => {
                    logout(); // Logg ut brukeren etter alert
                }, 100); // Liten forsinkelse før utlogging
            } else {
                console.log(`User ${username} is not frozen.`);
            }
        } else {
            console.error('Noe gikk galt ved sjekk av fryse-status:', response.status);
        }
    } catch (error) {
        console.error('Feil ved sjekk av fryse-status:', error);
    }
};


  // Bruk useEffect for å sjekke serverstatus, fryse-status og token utløp ved komponentens opplasting
  useEffect(() => {
    checkServerStatus();
    const interval = setInterval(() => {
      checkServerStatus(false);
      checkIfUserIsFrozen();
    }, 10000);  // Sjekk hvert 10. sekund

    // Rydd opp intervallet når komponenten demonteres
    return () => clearInterval(interval);
  }, []);

  // Funksjon for å håndtere manuell sjekk av serverstatus
  const handleClick = () => {
    setIsManualCheck(true);
    checkServerStatus(true).finally(() => {
      setTimeout(() => {
        setIsManualCheck(false);
      }, 1000);  // Reset manuell sjekk etter 1 sekund
    });
  };

  // Returner UI for serverstatusen
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography
        onClick={handleClick}
        sx={{
          color: isServerUp ? '#878787' : 'red',
          marginRight: '10px',
          fontSize: '0.8rem',
          cursor: 'pointer',
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
      >
        {isManualCheck ? 'Checking...' : serverStatus}
      </Typography>
    </Box>
  );
}

export default ServerStatus;

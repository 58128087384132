import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';  // Importer js-cookie for å håndtere cookies
import TabView from '../components/shared/TabView';  // Importer TabView-komponenten
import AdminPanel from '../components/_admin/AdminPanel';  // Importer AdminPanel-komponenten
import ResetPassword from './ResetPassword';

function Settings() {
  const [role, setRole] = useState(null);  // Lokal tilstand for role

  // useEffect for å hente role fra cookie når komponenten laster inn
  useEffect(() => {
    const storedRole = Cookies.get('role');  // Hent rollen fra cookie
    if (storedRole) {
      setRole(storedRole);  // Sett rollen i state hvis den finnes
    }
  }, []);  // Tom avhengighetsliste betyr at denne koden kun kjøres én gang ved innlasting

  // Faner og innhold
  const tabLabels = ['GENERAL', 'ACCOUNT'];  // Standardfaner for innstillinger

  const tabContents = [
    <div>General Settings</div>,
    <div>Account Settings
      <ResetPassword />
    </div>,
  ];

  // Legg til ekstra faner basert på brukerens rolle
  if (role === 'admin') {
    tabLabels.push('ADMIN');
    tabContents.push(<AdminPanel />);  // Render AdminPanel-komponenten for admin
  }

  if (role === 'technician') {
    tabLabels.push('TEKNIKER INNSTILLINGER');
    tabContents.push(<div>Tekniker-spesifikke innstillinger</div>);
  }

  // Funksjon for å håndtere lagring av innstillinger
  const handleSaveSettings = () => {
    console.log("Lagrer innstillingene");
    // Legg til logikk for å lagre innstillinger
  };

  return (
    <div>
      <h1>Settings</h1>
      <p></p>
      <TabView tabLabels={tabLabels} tabContents={tabContents} />  {/* Bruker TabView-komponenten */}
    </div>
  );
}

export default Settings;

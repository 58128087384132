import React, { useState } from 'react';
import { Layout, Button, Typography, Input, message } from 'antd';
import axios from 'axios';
import Cookies from 'js-cookie';  // Importer js-cookie for å håndtere cookies
import { useNavigate } from 'react-router-dom';

const { Content } = Layout;
const { Title, Text } = Typography;

function ResetPassword() {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Hent API-URL fra .env
  const API_URL = process.env.REACT_APP_STATUS_API_URL || 'http://localhost:5000/api';

  // Funksjon for å hente JWT fra cookie
  const getJwtFromCookie = () => {
    const token = Cookies.get('token');  // Bruk js-cookie for å hente token
    return token ? token : null;
  };

  // Funksjon for å logge ut brukeren ved å fjerne JWT-token og andre relevante cookies
  const logoutUser = () => {
    Cookies.remove('token');  // Fjern token fra cookies
    Cookies.remove('role');   // Fjern rollen fra cookies
    Cookies.remove('brands'); // Fjern brands fra cookies, hvis relevant
    message.success('You have been logged out.');
    setTimeout(() => {
      navigate('/login');  // Omdiriger brukeren til påloggingssiden
    }, 1000);
  };

  // Funksjon for å håndtere tilbakestilling av passord
  const handleResetPassword = async () => {
    if (!oldPassword || !newPassword || !confirmNewPassword) {
      setError('All fields must be filled out');
      message.error('Please fill out all fields.');
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setError('The new passwords do not match');
      message.error('The new passwords do not match.');
      return;
    }

    const token = getJwtFromCookie(); // Hent token fra cookie

    if (!token) {
      message.error('Authentication token is missing.');
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/user/change_password`, {
        old_password: oldPassword,
        new_password: newPassword
      }, {
        headers: {
          Authorization: `Bearer ${token}`, // Legg til token i autorisasjonsheaderen
        }
      });

      if (response.data.error) {
        message.error(response.data.error);  // Viser feilmeldingen fra backend
      } else {
        message.success('Password has been reset! Logging out...');
        // Tilbakestill feltene etter vellykket passordbytte
        setOldPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
        setError('');

        // Logg ut brukeren etter passordbytte
        logoutUser();
      }
    } catch (error) {
      if (error.response && error.response.data) {
        message.error(error.response.data.error || 'Failed to reset password.');
      } else {
        message.error('Failed to reset password.');
      }
    }
  };

  // Funksjon for å håndtere Enter-tasten
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleResetPassword();
    }
  };

  return (
    <Layout
      style={{
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-start', // Plasserer til venstre
        alignItems: 'flex-start', // Starter øverst til venstre
        backgroundColor: '#1e1e1e',
        paddingLeft: '50px', // Gir en margin til venstre
      }}
    >
      <Content style={{ textAlign: 'left', maxWidth: '400px', width: '100%' }}> {/* Setter breddebegrensning */ }
        <Title style={{ color: '#ffffff' }} level={2}>
          Reset Password
        </Title>
        <Input.Password
          placeholder="Old password"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          onKeyDown={handleKeyDown}
          style={{
            marginBottom: 16,
            backgroundColor: '#252526',
            color: '#ffffff',
            '::placeholder': { color: '#ffffff' }, // Placeholder-farge
          }}
        />
        <Input.Password
          placeholder="New password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          onKeyDown={handleKeyDown}
          style={{
            marginBottom: 16,
            backgroundColor: '#252526',
            color: '#ffffff',
            '::placeholder': { color: '#ffffff' }, // Placeholder-farge
          }}
        />
        <Input.Password
          placeholder="Confirm new password"
          value={confirmNewPassword}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
          onKeyDown={handleKeyDown}
          style={{
            marginBottom: 16,
            backgroundColor: '#252526',
            color: '#ffffff',
            '::placeholder': { color: '#ffffff' }, // Placeholder-farge
          }}
        />
        {error && <Text type="danger">{error}</Text>}
        <Button type="primary" onClick={handleResetPassword}>
          Reset Password
        </Button>
      </Content>
    </Layout>
  );
}

export default ResetPassword;

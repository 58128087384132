import React from 'react';
import { Box, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

function SidebarElement({ icon, label }) {
  return (
    <Box
      sx={{
        textAlign: 'center',
        padding: '16px',
        transition: 'background-color 0.3s ease, transform 0.01s ease',
        '&:hover': {
          backgroundColor: '#303031',
        },
        '&:active': {
          backgroundColor: '#505051',
          transform: 'scale(0.95)',
        },
      }}
    >
      <Box
        sx={{
          display: 'inline-block',
          padding: '8px',
          borderRadius: '8px',
        }}
      >
        {React.cloneElement(icon, { sx: { fontSize: 40, color: '#1677ff' } })}  {/* Endre farge her */}
      </Box>
      <Typography sx={{ marginTop: '8px', color: '#c5c5c5' }}>
        {label}
      </Typography>
    </Box>
  );
}

export default SidebarElement;

import React, { useState, useEffect } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, Button, IconButton, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const ActivitySelectionDialog = ({ activities, onSelectActivity, onClose }) => {
    const [openItems, setOpenItems] = useState([]); // For å spore hvilke elementer som er utvidet

    // Håndterer utvidelse av elementer i listen
    const handleExpandCollapse = (activityNumber) => {
        if (openItems.includes(activityNumber)) {
            setOpenItems(openItems.filter(item => item !== activityNumber));
        } else {
            setOpenItems([...openItems, activityNumber]);
        }
    };

    // Håndterer valg av aktivitet
    const handleSelectActivity = (activity) => {
        onSelectActivity(activity);  // Velger aktiviteten
        onClose();  // Lukker dialogen etter valg
    };

    // Legger til støtte for å velge aktivitet ved å trykke på tallene 1-9
    useEffect(() => {
        const handleKeyDown = (event) => {
            const key = event.key;
            const activityIndex = parseInt(key, 10) - 1;  // Konverterer tastetrykk til indeks (1-9)
            if (activityIndex >= 0 && activityIndex < activities.length) {
                handleSelectActivity(activities[activityIndex]);
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [activities, onSelectActivity]);

    return (
        <Dialog
            open={true}
            onClose={onClose}
            maxWidth="md"
            sx={{
                '& .MuiDialog-paper': {
                    width: '500px',
                    padding: '16px',
                    backgroundColor: '#1e1e1e',  // Mørk bakgrunn
                    color: '#fff',  // Hvit tekst
                    borderRadius: '8px',
                }
            }}
        >
            <DialogTitle>
                Velg en aktivitet
            </DialogTitle>
            <DialogContent>
                {activities.map((activity, index) => {
                    const isExpanded = openItems.includes(activity.orderData.activityNumber);
                    return (
                        <Box
                            key={index}
                            onClick={() => handleSelectActivity(activity)}  // Gjør hele boksen klikkbar for valg
                            sx={{
                                border: '1px solid #ccc',
                                borderRadius: '8px',
                                padding: '16px',
                                marginBottom: '16px',
                                backgroundColor: isExpanded ? '#444' : '#2e2e2e', // Endre farge når utvidet
                                position: 'relative',
                                cursor: 'pointer',  // Musen endres til pekeren for hele boksen
                                '&:hover': {
                                    backgroundColor: '#565656',
                                },
                            }}
                        >
                            <Typography variant="h6">
                                {index + 1}. Act no: {activity.orderData.activityNumber}
                            </Typography>
                            <Typography variant="body2">
                                Status: {activity.currentStatus}
                            </Typography>
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();  // Hindre klikk på boksen fra å trigge valg
                                    handleExpandCollapse(activity.orderData.activityNumber);
                                }}
                                sx={{
                                    position: 'absolute',
                                    right: '8px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                }}
                            >
                                {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>

                            {isExpanded && (
                                <Box sx={{ marginTop: '16px' }}>
                                    <Typography variant="body2">Created at: {new Date(activity.createdAt).toLocaleString()}</Typography>
                                    <Typography variant="body2">Consumer: {activity.consumer?.name || 'N/A'}</Typography>
                                    {/* Legg til andre felter her om nødvendig */}
                                </Box>
                            )}
                        </Box>
                    );
                })}

                <Button
                    onClick={onClose}
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ marginTop: '16px', backgroundColor: '#565656', color: '#fff' }}
                >
                    Lukk
                </Button>
            </DialogContent>
        </Dialog>
    );
};

export default ActivitySelectionDialog;

// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Dashboard from './components/Dashboard';
import Activities from './components/Activities';
import Stock from './components/Stock';  
import Shipping from './components/Shipping';  
import Login from './components/Login';
import Settings from './components/Settings';  
import ChatComponent from './components/_social/Chat'; // Importer ChatComponent
import { CssBaseline, Box, ThemeProvider } from '@mui/material';
import 'antd/dist/reset.css';
import { darkTheme } from './themes/themes';
import { AuthProvider } from './components/AuthContext';  
import StatusBar from './components/StatusBar';  
import ProtectedRoute from './components/ProtectedRoute';
import ErrorBoundary from './components/ErrorBoundary';

const themeToUse = darkTheme;

function App() {
  return (
    <ThemeProvider theme={themeToUse}>
      <CssBaseline />
      <AuthProvider>
        <Router>
          <ErrorBoundary>
            <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
              <Box sx={{ display: 'flex', flexGrow: 1 }}>
                <Sidebar />
                <Box
                  component="main"
                  sx={{
                    flexGrow: 1,
                    bgcolor: 'background.default',
                    color: 'text.primary',
                    p: 3,
                  }}
                >
                  <Routes>
                    <Route
                      path="/dashboard"
                      element={
                        <ProtectedRoute>
                          <Dashboard />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/activities"
                      element={
                        <ProtectedRoute>
                          <Activities />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/stock"
                      element={
                        <ProtectedRoute>
                          <Stock /> 
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/shipping"
                      element={
                        <ProtectedRoute>
                          <Shipping />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/settings"
                      element={
                        <ProtectedRoute>
                          <Settings />
                        </ProtectedRoute>
                      }
                    />
                    {/* Legg til Social-ruten her */}
                    <Route
                      path="/social"
                      element={
                        <ProtectedRoute>
                          <ChatComponent />
                        </ProtectedRoute>
                      }
                    />
                    <Route path="/login" element={<Login />} />
                  </Routes>
                </Box>
              </Box>
              <StatusBar />
            </Box>
          </ErrorBoundary>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;

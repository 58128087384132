import React from 'react';
import { Grid, Paper } from '@mui/material';
import BomListManagerTable from './BomListManagerTable'; // Importer tabellkomponenten

function BomListManagerGrid({ columns, rows, boxTitles }) {
  // Definer stilene for grid-layout
  const styles = {
    gridContainer: {
      padding: '20px',
    },
    gridItem: {
      padding: '10px',
      display: 'flex',           // For å sørge for at tabellene tar full høyde
      flexDirection: 'column',   // Slik at innholdet (som tabellen) strekker seg vertikalt
    },
    paper: {
      padding: '10px',
      backgroundColor: '#222',   // Mørk bakgrunn for tabellområdet
      color: '#fff',
      height: '100%',            // For å sikre at papiret tar hele grid-cellens høyde
      width: '100%',             // Full bredde
    },
    tableContainer: {
      flexGrow: 1,               // Sørg for at tabellen vokser for å fylle hele området
      maxHeight: '300px',        // Maks høyde for tabell, slik at vi får scroll ved mye innhold
      overflowY: 'auto',         // Sørg for at vi kan scrolle når innholdet er for stort
    },
    boxLabel: {
      fontSize: '12px',          // Liten skriftstørrelse for boksenavn
      color: '#bbb',             // Lysere farge for å skille den ut fra hovedtittelen
      marginBottom: '5px',
    },
  };

  return (
    <Grid container spacing={2} style={styles.gridContainer}>
      {[...Array(8)].map((_, index) => (
        <Grid item xs={12} sm={6} key={index} style={styles.gridItem}> {/* xs={12} strekker til full bredde på små skjermer */}
          <Paper style={styles.paper}>
            {/* Legg til en liten tekst over tabelltittel */}
            <div style={styles.boxLabel}>Box {index + 1}</div>
            <h3>{boxTitles[`box${index + 1}`] || ''}</h3>
            <div style={styles.tableContainer}>
              <BomListManagerTable columns={columns} rows={rows} /> {/* Tabell i scrollbar-container */}
            </div>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}

export default BomListManagerGrid;

import React, { useContext, useState } from 'react';
import { Layout, Button, Typography, Input, message } from 'antd';
import { AuthContext } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import './Login.css';  // Import the Login.css file

const { Content } = Layout;
const { Title, Text } = Typography;

function Login() {
  const { isAuthenticated, login, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async () => {
    // Remove old cookies before login
    Cookies.remove('token');
    Cookies.remove('role');
    Cookies.remove('brands');

    const result = await login(username, password);
    if (result.success) {
      navigate('/dashboard');
      message.success('Successfully logged in!');
    } else {
      setError(result.message);
      message.error(result.message);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <Layout
      style={{
        display: 'grid',
        placeItems: 'center',
        height: '100vh',
        backgroundColor: '#2d2d2d',
      }}
    >
      <Content
        style={{
          textAlign: 'center',
          backgroundColor: '#3a3a3a',
          padding: '40px',
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          width: '100%',
          maxWidth: '400px',
        }}
      >
        <Title style={{ color: '#ffffff' }} level={2}>
          {isAuthenticated ? 'You are logged in' : 'Login'}
        </Title>
        {!isAuthenticated && (
          <>
            <Input
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              onKeyDown={handleKeyDown}
              style={{ marginBottom: 16, backgroundColor: '#252526', color: '#ffffff' }}
            />
            <Input.Password
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={handleKeyDown}
              style={{ marginBottom: 16, backgroundColor: '#252526', color: '#ffffff' }}
            />
            {error && <Text type="danger">{error}</Text>}
          </>
        )}
        {isAuthenticated ? (
          <Button type="primary" danger onClick={logout}>
            Logout
          </Button>
        ) : (
          <Button type="primary" onClick={handleLogin}>
            Login
          </Button>
        )}
      </Content>
    </Layout>
  );
}

export default Login;
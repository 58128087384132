import React, { useContext } from 'react';
import { Drawer, Box, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import AssignmentIcon from '@mui/icons-material/Assignment';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupIcon from '@mui/icons-material/Group'; // Legger til ikonet for Social
import SidebarElement from './SidebarElement';
import { AuthContext } from './AuthContext';
import Logo from '../assets/Elcare_Nordic_white.png';

function Sidebar() {
  const { isAuthenticated, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleLogin = () => {
    navigate('/login');
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 180,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: 180,
          boxSizing: 'border-box',
          backgroundColor: '#252526',
          color: '#ffffff',
        },
      }}
    >
      <Box sx={{ padding: '8px 16px 0 16px', textAlign: 'center' }}>
        <img src={Logo} alt="Verksted Logo" style={{ width: '100%', height: 'auto' }} />
      </Box>

      <Box sx={{ padding: '4px 16px', textAlign: 'center', color: '#ffffff', marginTop: '-10px' }}>
        InStore
      </Box>

      {isAuthenticated && (
        <Box sx={{ paddingTop: '16px' }}>
          <div onClick={() => handleNavigate('/dashboard')}>
            <SidebarElement icon={<HomeIcon sx={{ fontSize: 40, color: '#4caf50' }} />} label="Dashboard" />
          </div>
          <div onClick={() => handleNavigate('/activities')}>
            <SidebarElement icon={<AssignmentIcon sx={{ fontSize: 40, color: '#4caf50' }} />} label="Activities" />
          </div>
          <div onClick={() => handleNavigate('/stock')}>
            <SidebarElement icon={<InventoryIcon sx={{ fontSize: 40, color: '#4caf50' }} />} label="Stock" />
          </div>
          <div onClick={() => handleNavigate('/shipping')}>
            <SidebarElement icon={<LocalShippingIcon sx={{ fontSize: 40, color: '#4caf50' }} />} label="Shipping" />
          </div>
          <div onClick={() => handleNavigate('/social')}> {/* Ny meny for Social */}
            <SidebarElement icon={<GroupIcon sx={{ fontSize: 40, color: '#4caf50' }} />} label="Social" />
          </div>
        </Box>
      )}

      <Box sx={{ flexGrow: 1 }} />

      {isAuthenticated && (
        <Box sx={{ padding: 2, textAlign: 'center' }}>
          <div onClick={() => handleNavigate('/settings')}>
            <SidebarElement icon={<SettingsIcon sx={{ fontSize: 40, color: '#4caf50' }} />} label="Settings" />
          </div>
        </Box>
      )}

      <Box sx={{ padding: 2, textAlign: 'center' }}>
        {isAuthenticated ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleLogout}
            sx={{ width: '100%' }}
          >
            Logout
          </Button>
        ) : (
          location.pathname !== '/login' && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleLogin}
              sx={{ width: '100%' }}
            >
              Login
            </Button>
          )
        )}
      </Box>
    </Drawer>
  );
}

export default Sidebar;

import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from './AuthContext';
import TabView from '../components/shared/TabView';
import { Box, TextField, Button, CircularProgress } from '@mui/material';
import ActivityList from './_activities/ActivityList';
import ActivitySelectionDialog from './_activities/ActivitySelectionModal';
import ActivityEditor from './_activities/ActivityEditor';
import Cookies from 'js-cookie';
import BomListManager from './_samsung/BomListManager';
import Queues from './Queues';

function Activities() {
  const { userRole } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [activityNumber, setActivityNumber] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [imeiNumber, setImeiNumber] = useState('');
  const [customerReference, setCustomerReference] = useState('');
  const [activityData, setActivityData] = useState([]);  // Lagre liste over aktiviteter
  const [selectedActivity, setSelectedActivity] = useState(null);  // Lagre valgt aktivitet
  const [multipleResults, setMultipleResults] = useState([]);
  const [showDialog, setShowDialog] = useState(false);

  // Ny state for brands
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    // Hent brands fra cookies og dekode dem
    const storedBrands = Cookies.get('brands');
    if (storedBrands) {
      try {
        const decodedBrands = JSON.parse(decodeURIComponent(storedBrands)); // Dekoder cookien
        setBrands(decodedBrands);
      } catch (error) {
        console.error("Failed to decode brands cookie:", error);
      }
    }
  }, []);

  useEffect(() => {
    console.log('Brands state:', brands);
  }, [brands]);



  const searchForDevice = async (actNumber) => {
    setLoading(true);
    const apiUrl = process.env.REACT_APP_STATUS_API_URL;
    let searchParam = actNumber ? `act_number=${actNumber}` : '';
    
    const url = `${apiUrl}/data?${searchParam}`;
    const token = Cookies.get('token');
    
    if (!token) {
      alert('Du må være innlogget for å søke etter en serviceordre.');
      setLoading(false);
      return;
    }
    
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
    
      if (response.status === 401) {
        alert('Token utløpt eller ugyldig. Vennligst logg inn på nytt.');
        setLoading(false);
        return;
      }
    
      if (response.ok) {
        const jsonResponse = await response.json();
        if (jsonResponse && jsonResponse.length > 0) {
          const newActivity = jsonResponse[0];
          const exists = activityData.some(
            activity => activity.orderData?.activityNumber === newActivity.orderData?.activityNumber
          );
    
          if (!exists) {
            setActivityData(prevData => [newActivity, ...prevData]);
            setSelectedActivity(newActivity);
          } else {
            setSelectedActivity(newActivity);
          }
        } else {
          alert('Ingen saker funnet for det oppgitte søket.');
        }
      } else {
        alert(`Feil ved forespørsel: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Feil ved søk:', error);
    } finally {
      setLoading(false);
      // Tøm søkefeltene etter søk
      setActivityNumber('');
      setSerialNumber('');
      setImeiNumber('');
      setCustomerReference('');
    }
  };
  
  const handleSelectActivity = (activity) => {
    setSelectedActivity(activity);  // Sett kun den valgte aktiviteten for visning i editor
    setShowDialog(false);
    setActivityNumber('');
    setSerialNumber('');
    setImeiNumber('');
    setCustomerReference('');
  };

  const handleInputChange = (e, field) => {
    const { value } = e.target;

    switch (field) {
      case 'activityNumber':
        setActivityNumber(value);
        setSerialNumber('');
        setImeiNumber('');
        setCustomerReference('');
        break;
      case 'serialNumber':
        setSerialNumber(value);
        setActivityNumber('');
        setImeiNumber('');
        setCustomerReference('');
        break;
      case 'imeiNumber':
        setImeiNumber(value);
        setActivityNumber('');
        setSerialNumber('');
        setCustomerReference('');
        break;
      case 'customerReference':
        setCustomerReference(value);
        setActivityNumber('');
        setSerialNumber('');
        setImeiNumber('');
        break;
      default:
        break;
    }
  };

  // Funksjon for å lagre endringer til backend
  const handleSave = async (newData) => {
    console.log("Lagrer ny verdi:", newData);
  
    const apiUrl = process.env.REACT_APP_STATUS_API_URL;
    const updateUrl = `${apiUrl}/update_case`;  // Oppdatering av saken
    const token = Cookies.get('token');
  
    if (!token) {
      alert('Du må være innlogget for å oppdatere en sak.');
      return;
    }
  
    // Bygg strukturen for dataene i samsvar med det riktige formatet
    const payload = {
      guid: newData.guid,  // Unik identifikator for saken
      orderData: {
        activityNumber: newData.activityNumber,
        consents: newData.consents || {},
        providerSpecific: newData.providerSpecific || {},
        refNo: newData.refNo,
        shipmentData: newData.shipmentData || {},
        bookingType: newData.bookingType,
        serviceProviderExportStatus: newData.serviceProviderExportStatus,
        clientPostalCode: newData.clientPostalCode,
      },
      productData: {
        imei: newData.productImei,
        model: newData.productModel,
        serial: newData.productSerial,
        problemText: newData.problemText,
        purchaseDate: newData.purchaseDate,
      },
      customer: {
        name: newData.customerName,
        address: newData.customerAddress,
        postalCode: newData.customerPostalCode,
        city: newData.customerCity,
        email: newData.customerEmail,
        phone: newData.customerPhone,
      },
      tags: newData.tags || [],  // Pass på at tags sendes som en liste
    };
  
    try {
      const response = await fetch(updateUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(payload),  // Send formaterte data til backend
      });
  
      if (response.ok) {
        const result = await response.json();
        alert('Saken er oppdatert!');
        console.log('Update result:', result);
      } else {
        alert(`Feil ved oppdatering: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Feil ved oppdatering:', error);
    }
  };
  
  const spaceForEditor = (
    <Box sx={{ height: '300px', backgroundColor: '#636363', marginBottom: '5px' }} />
  );

  const activitySmallTabLabels = ['Activity', 'A-Reports', 'Order', 'Comments', 'Log'];

  const activitySmallTabContents = [
    loading ? (
      <CircularProgress />
    ) : selectedActivity ? (
      <div>
        <ActivityEditor initialData={selectedActivity} onSubmit={handleSave} />
      </div>
    ) : (
      <div />
    ),
    <div>{spaceForEditor}</div>,
    <div>{spaceForEditor}</div>,
    <div>{spaceForEditor}</div>,
    <div>{spaceForEditor}</div>
  ];

  // Filtrer aktiviteter basert på status
  const filterActivities = (status) => {
    return activityData.filter(activity => activity.currentStatus === status);
  };

  const queuesSmallTabLabels = ['Not started', 'Open', 'Waiting', 'Completed'];
  const queuesSmallTabContents = [
    <ActivityList activities={filterActivities('Not started')} />,
    <ActivityList activities={filterActivities('Open')} />,
    <ActivityList activities={filterActivities('Waiting')} />,
    <ActivityList activities={filterActivities('Completed')} />
  ];

  const samsungSmallTabLabels = ['Create SO', 'Repairs', 'BOM List'];
  const samsungSmallTabContents = [
    <div>Innhold for Sub-tab 1</div>,
    <div>Innhold for Sub-tab 2</div>,
    <div>
      <BomListManager />
    </div>,
  ];

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      searchForDevice(activityNumber);
    }
  };

  const openAct = (
    <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
      <TextField
        label="Act Number"
        variant="outlined"
        fullWidth
        value={activityNumber}
        onChange={(e) => handleInputChange(e, 'activityNumber')}
        onKeyPress={handleKeyPress}
        sx={{ input: { color: '#fff' }, label: { color: '#c5c5c5' } }}
      />
      <TextField
        label="Serial Number"
        variant="outlined"
        fullWidth
        value={serialNumber}
        onChange={(e) => handleInputChange(e, 'serialNumber')}
        onKeyPress={handleKeyPress}
        sx={{ input: { color: '#fff' }, label: { color: '#c5c5c5' } }}
      />
      <TextField
        label="IMEI Number"
        variant="outlined"
        fullWidth
        value={imeiNumber}
        onChange={(e) => handleInputChange(e, 'imeiNumber')}
        onKeyPress={handleKeyPress}
        sx={{ input: { color: '#fff' }, label: { color: '#c5c5c5' } }}
      />
      <TextField
        label="Customer Reference"
        variant="outlined"
        fullWidth
        value={customerReference}
        onChange={(e) => handleInputChange(e, 'customerReference')}
        onKeyPress={handleKeyPress}
        sx={{ input: { color: '#fff' }, label: { color: '#c5c5c5' } }}
      />
      <Button
        variant="contained"
        color="primary"
        sx={{ height: '56px' }}
        onClick={() => searchForDevice(activityNumber)}
      >
        Search
      </Button>
    </Box>
  );

  const tabLabels = ['HANDLING', 'QUEUES'];
  const tabContents = [
    <div>
      {openAct}
      <TabView
        tabLabels={activitySmallTabLabels}
        tabContents={activitySmallTabContents}
        variant="small"
        customSmallDimensions={{
          minHeight: 'calc(100vh - 1000px)',
          maxHeight: 'calc(100vh - 350px)',
        }}
      />

      Recently opened activities:
      <ActivityList
        activities={activityData}
        onSelectActivity={handleSelectActivity}
        searchForDevice={searchForDevice}
        setActivityData={setActivityData}
      />
    </div>,
    <div>
      <Queues activityData={activityData} />
    </div>
  ];

  if (brands.includes('apple')) {
    tabLabels.push('APPLE');
    tabContents.push(<div>Apple Cases</div>);
  }

  if (brands.includes('samsung')) {
    tabLabels.push('SAMSUNG');
    tabContents.push(<div>
      <TabView tabLabels={samsungSmallTabLabels} tabContents={samsungSmallTabContents} variant="small" />
    </div>);
  }

  return (
    <div>
      <h1>Activities</h1>
      <p>Handling repairs and internal service tracking.</p>
      <TabView tabLabels={tabLabels} tabContents={tabContents} />

      {showDialog && (
        <ActivitySelectionDialog
          activities={multipleResults}
          onSelectActivity={handleSelectActivity}
          onClose={() => setShowDialog(false)}
        />
      )}
    </div>
  );
}

export default Activities;

import React, { createContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';  // Importer js-cookie

// Funksjon for å sjekke om brukeren er frosset
const checkIfUserIsFrozen = async () => {
  const apiUrl = process.env.REACT_APP_STATUS_API_URL;
  const token = Cookies.get('token');  // Hent JWT-token fra cookies

  if (!token) {
    console.error('No token found.');
    return false;
  }

  try {
    const decodedToken = jwtDecode(token);
    const username = decodedToken.sub;  // Hent 'sub' fra tokenet som brukernavn

    const response = await fetch(`${apiUrl}/check_freeze`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,  // Send JWT-token i headeren
      },
      body: JSON.stringify({ username }),  // Send brukernavn i body
    });

    if (response.ok) {
      const data = await response.json();
      return data.frozen === 'true';  // Returner true hvis brukeren er frosset
    } else {
      const errorText = await response.text();
      console.error('Somthing went wrong with the response from the server:', response.status, errorText);
      return false;
    }
  } catch (error) {
    console.error('Error while checking freeze status:', error);
    return false;
  }
};

// Opprett en AuthContext
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState(null);
  const [brands, setBrands] = useState([]);  // Legg til brands som en state

  // Funksjon for å håndtere innlogging
// Funksjon for å håndtere innlogging
const login = async (username, password) => {
  try {
    const apiUrl = process.env.REACT_APP_STATUS_API_URL;

    const response = await fetch(`${apiUrl}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });

    if (response.ok) {
      const data = await response.json();

      // Hvis serveren svarer med en spesifikk feilmelding, sjekk for det
      if (data.error === 'Account is frozen') {
        console.warn('Login cancelled: Account is frozen by admin.');
        return { success: false, message: 'Account is frozen by admin' };
      }

      const decodedToken = jwtDecode(data.access_token); // Dekod token for å hente utløpstid
      const expirationDate = new Date(decodedToken.exp * 1000); // Konverter til millisekunder

      // Lagre token, rolle, og brands i cookies med utløpstid
      //Cookies.set('token', data.access_token, { expires: expirationDate });
      //Cookies.set('role', data.role, { expires: expirationDate });
      ////Cookies.set('brands', data.brands, { expires: expirationDate });  // Lagre brands
      //Cookies.set('brands', JSON.stringify(data.brands), { expires: expirationDate });  // Sørg for at det settes som en JSON-struktur

      // Bruk secure flag hvis vi er i produksjon
      const isProduction = process.env.REACT_APP_STATUS_API_URL === 'https://scripthub.serviceorderhub.com/dashboardapi';

      // Sett token, role og brands som JSON-struktur med sikkerhetsinnstillinger for produksjon
      Cookies.set('token', data.access_token, { 
        expires: expirationDate, 
        secure: isProduction,  // Kun sikre cookies i produksjon
        sameSite: 'Strict'     // Ekstra sikkerhet for å forhindre cross-site requests
      });

      Cookies.set('role', data.role, { 
        expires: expirationDate, 
        secure: isProduction,  // Kun sikre cookies i produksjon
        sameSite: 'Strict'
      });

      Cookies.set('brands', JSON.stringify(data.brands), { 
        expires: expirationDate, 
        secure: isProduction,  // Kun sikre cookies i produksjon
        sameSite: 'Strict'
      });


    


      setIsAuthenticated(true);
      setRole(data.role);  // Lagre brukerens rolle i state
      return { success: true };
    } else {
      const errorData = await response.json();
      console.error('Login failed:', errorData.error);
      return { success: false, message: errorData.error || 'Wrong username or password.' };
    }
  } catch (error) {
    console.error('Login failed:', error);
    return { success: false, message: 'Could not login. Please try again later.' };
  }
};


  // Funksjon for å håndtere utlogging
  const logout = () => {
    Cookies.remove('token');  // Fjern token fra cookies
    Cookies.remove('role');   // Fjern rollen fra cookies
    Cookies.remove('brands'); // Fjern brands fra cookies
    setIsAuthenticated(false);  // Nullstill autentiseringstilstanden
    setRole(null);  // Nullstill rollen
    setBrands([]);  // Nullstill brands
  };

  // Funksjon for å sjekke om token er utløpt
  const isTokenExpired = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;  // Nåværende tid i sekunder
      return decodedToken.exp < currentTime;  // Returner true hvis tokenet er utløpt
    } catch (error) {
      console.error('Error while decoding the token.:', error);
      return true;  // Hvis det er feil, anta at tokenet er utløpt
    }
  };

  // Sjekk token, role og brands ved første innlasting av appen
  useEffect(() => {
    const verifyTokenAndFreezeStatus = async () => {
      const token = Cookies.get('token');
      const role = Cookies.get('role');  // Hent role fra cookies
      const brandsFromCookie = Cookies.get('brands');  // Hent brands fra cookies

      if (token && role && brandsFromCookie) {
        if (isTokenExpired(token)) {
          console.log('The token has expired');
          logout();  // Logg ut hvis tokenet er utløpt
        } else {
          const isFrozen = await checkIfUserIsFrozen();  // Sjekk om brukeren er frosset
          if (isFrozen) {
            console.log('Use account has been frozen');
            logout();
          } else {
            setIsAuthenticated(true);
            setRole(role);  // Sett rollen fra cookies
            setBrands(JSON.parse(brandsFromCookie));  // Sett brands fra cookies (husk å parse JSON)
          }
        }
      } else {
        logout();  // Hvis token, role eller brands mangler, logg ut brukeren
      }
    };

    verifyTokenAndFreezeStatus();
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, role, brands, login, logout, setIsAuthenticated, setRole, setBrands }}>
      {children}
    </AuthContext.Provider>
  );
};

import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { jwtDecode } from 'jwt-decode';  // Korrigert named import
import Cookies from 'js-cookie';  // Importer js-cookie for å hente token
import ServerStatus from './ServerStatus';

// Komponent for å formatere tekst
function FormatText({ text }) {
  return (
    <Typography
      sx={{
        color: '#878787',
        fontSize: '0.8rem',
        textAlign: 'right',
      }}
    >
      {text}
    </Typography>
  );
}

function StatusBar() {
  const [sessionTimeLeft, setSessionTimeLeft] = useState('');

  // Funksjon for å beregne hvor lenge det er igjen før sesjonen utløper
  const calculateTimeLeft = () => {
    const token = Cookies.get('token');  // Hent token fra cookies
    if (!token) {
      setSessionTimeLeft('Session expired');
      return;
    }

    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;  // Nåværende tid i sekunder
      const timeLeft = decodedToken.exp - currentTime;  // Tid igjen før utløp

      if (timeLeft <= 0) {
        setSessionTimeLeft('Session expired');
        return;
      }

      // Konverter til timer, minutter og sekunder
      const hours = Math.floor(timeLeft / 3600);
      const minutes = Math.floor((timeLeft % 3600) / 60);
      const seconds = Math.floor(timeLeft % 60);
      setSessionTimeLeft(`Session expiring in: ${hours}h ${minutes}m ${seconds}s`);
    } catch (error) {
      console.error('Feil ved dekoding av token:', error);
      setSessionTimeLeft('Error calculating session time');
    }
  };

  // useEffect for å oppdatere tiden igjen før utløp hvert sekund
  useEffect(() => {
    calculateTimeLeft();  // Kjør én gang ved første innlasting

    const interval = setInterval(() => {
      calculateTimeLeft();  // Oppdater hvert sekund
    }, 1000);  // 1000ms = 1 sekund

    return () => clearInterval(interval);  // Rydd opp når komponenten demonteres
  }, []);

  return (
    <Box
      sx={{
        width: 'calc(100% - 180px)',
        bgcolor: '#252526',
        color: '#ffffff',
        textAlign: 'left',
        p: 0.5,
        position: 'fixed',
        bottom: 0,
        left: 180,
        display: 'flex',
        justifyContent: 'space-between',  // For å spre elementene
        alignItems: 'center',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <FormatText text='System status:&nbsp;' />
        <ServerStatus />  {/* Bruker ServerStatus-komponenten */}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <FormatText text={sessionTimeLeft} />  {/* Viser hvor lenge sesjonen har igjen */}
      </Box>
    </Box>
  );
}

export default StatusBar;
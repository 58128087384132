import React, { useState } from 'react';

function BomListManagerSearchBar({ onSearch }) {
  const [modelCode, setModelCode] = useState('');
  const [serialNo, setSerialNo] = useState('');
  const [version, setVersion] = useState('');
  const [act, setAct] = useState('');

  const handleUpdateClick = () => {
    // Kjør søk eller oppdatering basert på input-verdier
    if (onSearch) {
      onSearch({ modelCode, serialNo, version, act });
    }
  };

  const handleImportClick = () => {
    // Importer data fra "act" input-feltet
    console.log('Importing data from act:', act);
  };

  // Innebygd stil-objekt
  const styles = {
    searchBar: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      backgroundColor: '#333',
      padding: '10px',
      color: 'white',
    },
    inputGroup: {
      marginRight: '20px',
    },
    input: {
      backgroundColor: '#222',
      color: 'white',
      border: '1px solid #555',
      marginLeft: '5px',
    },
    button: {
      backgroundColor: '#555',
      color: 'white',
      border: 'none',
      padding: '5px 10px',
      marginLeft: '10px',
      cursor: 'pointer',
    },
  };

  return (
    <div style={styles.searchBar}>
      <div style={styles.inputGroup}>
        <label>Enter model code:</label>
        <input
          type="text"
          value={modelCode}
          onChange={(e) => setModelCode(e.target.value)}
          style={styles.input}
        />
        <button onClick={handleUpdateClick} style={styles.button}>Update</button>
      </div>

      <div style={styles.inputGroup}>
        <fieldset>
          <legend>Optional API search alternatives</legend>
          <label>Serial no.:</label>
          <input
            type="text"
            value={serialNo}
            onChange={(e) => setSerialNo(e.target.value)}
            style={styles.input}
          />
          <label>Version:</label>
          <input
            type="text"
            value={version}
            onChange={(e) => setVersion(e.target.value)}
            style={styles.input}
          />
        </fieldset>
      </div>

      <div style={styles.inputGroup}>
        <label>Import data from Act:</label>
        <input
          type="text"
          value={act}
          onChange={(e) => setAct(e.target.value)}
          style={styles.input}
        />
        <button onClick={handleImportClick} style={styles.button}>Import</button>
      </div>
    </div>
  );
}

export default BomListManagerSearchBar;

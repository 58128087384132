import React, { useState } from 'react';
import { ListItem, ListItemText, Collapse, Box, Typography, IconButton } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ActivityDetails from './ActivityDetails';

const ActivityListElement = ({ activity, onClick, isMoving, isTop }) => {
  const [open, setOpen] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [clickTimeout, setClickTimeout] = useState(null);
  const [isCollapsing, setIsCollapsing] = useState(false);

  const handleExpandCollapse = (e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    setOpen(!open);
    if (open) {
      setIsCollapsing(true);
    }
  };

  const handleClick = () => {
    setIsClicked(true);
    setTimeout(() => setIsClicked(false), 200);

    if (clickTimeout) {
      clearTimeout(clickTimeout);
      setClickTimeout(null);
      handleExpandCollapse();
    } else {
      if (open) {
        setOpen(false);
        setIsCollapsing(true);
      } else {
        const timeout = setTimeout(() => {
          onClick();
          setClickTimeout(null);
        }, 300);
        setClickTimeout(timeout);
      }
    }
  };

  const handleTransitionEnd = () => {
    if (isCollapsing) {
      setIsCollapsing(false);
      onClick();
    }
  };

  // Fallback-verdier for manglende data
  const activityNumber = activity.orderData?.activityNumber || 'Unknown';
  const status = activity.currentStatus || 'N/A';
  const prodDesc = activity.orderData?.providerSpecific?.elcare?.prodDesc || 'N/A'; // Hent produktbeskrivelse
  const createdAt = new Date(activity.createdAt).toLocaleString() || 'N/A';
  const consumer = activity.consumer?.name || 'N/A';

  return (
    <Box
      sx={{
        position: 'relative',
        transition: isTop ? 'none' : 'transform 0.5s ease-in-out',
        transform: isMoving ? 'translateY(-50px)' : 'none',
        border: '1px solid #ccc',
        borderRadius: '8px',
        marginBottom: '16px',
        marginRight: '5px',
        marginLeft: '5px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        backgroundColor: isClicked ? '#3e3e3e' : '#1e1e1e',
        color: '#fff',
        transition: 'background-color 0.3s, transform 0.3s',
        '&:hover': {
          backgroundColor: '#565656',
        },
      }}
    >
      <ListItem
        onClick={handleClick}
        sx={{
          backgroundColor: '#2e2e2e',
          padding: '16px',
          borderBottom: '1px solid #444',
          borderRadius: open ? '8px 8px 0 0' : '8px',
          '&:hover': {
            backgroundColor: '#777777',
          },
        }}
      >
        <ListItemText
          primary={
            <>
              <Typography variant="body1" component="span" sx={{ fontWeight: 'bold' }}>
                Act no: {activityNumber}
              </Typography>
              {'   '}
              <Typography variant="body1" component="span" sx={{ fontWeight: 'bold', marginLeft: '20px' }}>
                Product: {prodDesc} {/* Legger til litt avstand (marginLeft) her */}
              </Typography>
            </>
          }
          secondary={`Status: ${status}`}
        />
        <IconButton
          onClick={handleExpandCollapse}
          sx={{ color: '#fff' }}
        >
          {open ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit onTransitionEnd={handleTransitionEnd}>
        <Box sx={{ padding: '16px', backgroundColor: '#1c0a03', borderRadius: '8px' }}>
          <Typography variant="body2" sx={{ marginBottom: '8px' }}>
            <strong>Created at:</strong> {createdAt}
          </Typography>
          <Typography variant="body2" sx={{ marginBottom: '8px' }}>
            <strong>Consumer:</strong> {consumer}
          </Typography>
          <ActivityDetails activity={activity} />
        </Box>
      </Collapse>
    </Box>
  );
};

export default ActivityListElement;

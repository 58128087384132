// ChatComponent.js
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Cookies from 'js-cookie';

const ChatContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 600px;
	height: 800px;
	border-radius: 10px;
	background: #2d2f33;
	color: white;
	box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
	padding: 1rem;
	font-family: Arial, sans-serif;
`;

const MessagesContainer = styled.div`
	flex: 1;
	overflow-y: auto;
	margin-bottom: 1rem;
	padding: 0.5rem;
	border-radius: 8px;
	background: #1c1e22;
	scrollbar-width: thin;

	&::-webkit-scrollbar {
		width: 8px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #555;
		border-radius: 10px;
	}
`;

const Message = styled.div`
	margin: 0.3rem 0;
	padding: 0.5rem;
	border-radius: 5px;
	background: ${(props) => (props.isUser ? '#101010' : '#3a3f47')};
	align-self: ${(props) => (props.isUser ? 'flex-end' : 'flex-start')};
	color: ${(props) => (props.isUser ? 'white' : '#ddd')};
	animation: fadeIn 0.3s ease;

	@keyframes fadeIn {
		from {
			opacity: 0;
			transform: translateY(5px);
		}
		to {
			opacity: 1;
			transform: translateY(0);
		}
	}
`;

const InputContainer = styled.div`
	display: flex;
	border-top: 1px solid #444;
	padding-top: 0.5rem;
`;

const ChatInput = styled.input`
	flex: 1;
	padding: 0.5rem;
	border: none;
	border-radius: 5px;
	background: #1e2125;
	color: white;
	outline: none;
	margin-right: 0.5rem;

	::placeholder {
		color: #888;
	}
`;

const SendButton = styled.button`
	padding: 0.5rem 1rem;
	border: none;
	border-radius: 5px;
	background: #4a9bfc;
	color: white;
	cursor: pointer;
	transition: background 0.2s ease;

	&:hover {
		background: #3580e3;
	}
`;

const ChatComponent = () => {
	const [messages, setMessages] = useState([]);
	const [input, setInput] = useState('');
	const messagesEndRef = useRef(null);

	const apiUrl = process.env.REACT_APP_STATUS_API_URL;

	const scrollToBottom = () => {
		if (messagesEndRef.current) {
			messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	};

	const fetchMessages = async () => {
		const token = Cookies.get('token');
		if (!token) {
			console.error('User is not authenticated');
			return;
		}

		try {
			const response = await fetch(`${apiUrl}/chat/get_messages`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`,
				},
				body: JSON.stringify({ limit: 100 })
			});
			if (response.ok) {
				const data = await response.json();
				setMessages(data.messages || []);
			} else {
				console.error('Error fetching messages:', response.statusText);
			}
		} catch (error) {
			console.error('Error fetching messages:', error);
		}
	};

	useEffect(() => {
		// Initial fetch when component mounts
		fetchMessages();

		// Polling interval to fetch messages every 5 seconds
		const interval = setInterval(fetchMessages, 5000); // Justér til et passende intervall
		return () => clearInterval(interval); // Cleanup on component unmount
	}, []);

	useEffect(() => {
		// Scroll to bottom every time messages change
		scrollToBottom();
	}, [messages]);

	const handleSendMessage = async () => {
		if (!input.trim()) return;

		const token = Cookies.get('token');
		if (!token) {
			alert('Du må være innlogget for å sende en melding.');
			return;
		}

		try {
			const response = await fetch(`${apiUrl}/chat/send_message`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`,
				},
				body: JSON.stringify({ username: 'YourUsername', message: input })
			});

			if (response.ok) {
				setInput('');
				await fetchMessages(); // Immediate fetch after sending message
			} else {
				console.error('Error sending message:', response.statusText);
			}
		} catch (error) {
			console.error('Error sending message:', error);
		}
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			handleSendMessage();
		}
	};

	return (
		<ChatContainer>
			<MessagesContainer>
				{messages
					.slice()
					.reverse() // Reverser meldingene for å få nyeste nederst
					.map((msg, index) => (
						<Message key={index} isUser={msg.username === 'YourUsername'}>
							<strong>{msg.username}:</strong> {msg.message}
							<div style={{ fontSize: '0.8em', color: '#aaa' }}>
								{new Date(msg.timestamp).toLocaleString()}
							</div>
						</Message>
					))}
				<div ref={messagesEndRef} />
			</MessagesContainer>
			<InputContainer>
				<ChatInput
					type="text"
					value={input}
					onChange={(e) => setInput(e.target.value)}
					onKeyPress={handleKeyPress}
					placeholder="Type your message..."
				/>
				<SendButton onClick={handleSendMessage}>Send</SendButton>
			</InputContainer>
		</ChatContainer>
	);
};

export default ChatComponent;

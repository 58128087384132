// src/themes/themes.js
import { createTheme } from '@mui/material/styles';

// Mørkt tema som ligner på VSCode
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#1e1e1e',
      paper: '#252526',
    },
    text: {
      primary: '#ffffff',
    },
  },
});

// Lyst tema som ligner på bildet du viste
const lightTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#f5f5f5',
      paper: '#ffffff',
    },
    text: {
      primary: '#333333',
      secondary: '#777777',
    },
    primary: {
      main: '#004085',     // Blåfarge
    },
    secondary: {
      main: '#ffc107',     // Gul farge
    },
    action: {
      hover: '#e9ecef',    // Lys hover-effekt
    },
    divider: '#dddddd',    // Grå skillelinjer
  },
});

// Eksporter begge temaene
export { darkTheme, lightTheme };

import React, { useState } from 'react';
import { Box, TextField, Button, CircularProgress, Typography, Card, CardContent } from '@mui/material';
import TabView from '../components/shared/TabView';
import ActivityList from './_activities/ActivityList';
import Cookies from 'js-cookie';

const apiUrl = process.env.REACT_APP_STATUS_API_URL;  // Henter API URL fra miljøvariabel

function Queues() {
  const [district, setDistrict] = useState('');
  const [group, setGroup] = useState('');
  const [filteredActivities, setFilteredActivities] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSearch = async () => {
    setLoading(true);
    const token = Cookies.get('token'); // Henter token fra cookies

    try {
      const response = await fetch(`${apiUrl}/search_by_district_and_group`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ district, group }),
      });

      if (response.ok) {
        const result = await response.json();
        setFilteredActivities(result.data || []);
      } else {
        console.error(`Error: ${response.statusText}`);
        alert('Kunne ikke hente data. Vennligst prøv igjen.');
      }
    } catch (error) {
      console.error('Network error:', error);
      alert('Nettverksfeil ved henting av data.');
    } finally {
      setLoading(false);
    }
  };

  const queuesSmallTabLabels = ['Not started', 'Open', 'Waiting', 'Completed'];
  const filterActivitiesByStatus = (status) =>
    filteredActivities.filter((activity) => activity.currentStatus === status);
  const queuesSmallTabContents = queuesSmallTabLabels.map((status) => (
    <ActivityList activities={filterActivitiesByStatus(status)} />
  ));

  return (
    <div>
      <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
        <TextField
          label="District"
          variant="outlined"
          fullWidth
          value={district}
          onChange={(e) => setDistrict(e.target.value)}
        />
        <TextField
          label="Group"
          variant="outlined"
          fullWidth
          value={group}
          onChange={(e) => setGroup(e.target.value)}
        />
        <Button variant="contained" color="primary" onClick={handleSearch}>
          Search
        </Button>
      </Box>

      {loading ? (
        <CircularProgress />
      ) : (
        <Box>
          {filteredActivities.length > 0 ? (
            filteredActivities.map((activity) => (
              <Card key={activity.caseNumber} sx={{ marginBottom: 2 }}>
                <CardContent>
                  <Typography variant="h6">Case Number: {activity.caseNumber}</Typography>
                  <Typography>Status: {activity.currentStatus}</Typography>
                  <Typography>Manufacturer: {activity.manufacturer?.name}</Typography>
                  <Typography>Customer Name: {activity.customer?.name}</Typography>
                  <Button variant="outlined" onClick={() => console.log('View details for', activity.caseNumber)}>
                    View Details
                  </Button>
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography>No cases found for the specified criteria.</Typography>
          )}
        </Box>
      )}
    </div>
  );
}

export default Queues;

import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Typography, Paper, Divider } from '@mui/material';
import UserList from './UserList'; // Importer den nye komponenten for å vise brukere
import TabView from '../shared/TabView'; // Importer TabView-komponenten

// Dette er URL-en til API-et
const API_URL = process.env.REACT_APP_STATUS_API_URL;
const API_KEY = 'mysecretadminkey'; // API-nøkkelen

// Hjelpefunksjon for å sende forespørsel til API-et
const sendAdminRequest = async (action, data = {}, method = 'POST') => {
  const urlMap = {
    'create_table': `${API_URL}/admin/create_table`,
    'delete_table': `${API_URL}/admin/delete_table`,
    'add_user': `${API_URL}/admin/add_user`,
    'delete_user': `${API_URL}/admin/delete_user`,
    'list_users': `${API_URL}/admin/list_users?api_key=${API_KEY}`,
    'locked_users': `${API_URL}/admin/locked_users`,  // Fjern ?api_key her, API-nøkkel sendes i body for POST
    'frozen_users': `${API_URL}/admin/frozen_users?api_key=${API_KEY}`,
    'freeze_user': `${API_URL}/admin/freeze_user`,
    'unfreeze_user': `${API_URL}/admin/unfreeze_user`,
    'check_freeze': `${API_URL}/check_freeze`,
    'reset_lock_count': `${API_URL}/admin/reset_lock_count`,
  };

  const url = urlMap[action];
  if (!url) {
    console.error(`Ukjent handling: ${action}`);
    return;
  }

  const headers = { 'Content-Type': 'application/json' };

  try {
    let response;
    if (method === 'GET') {
      response = await fetch(url, { method, headers });
    } else {
      const bodyWithApiKey = { ...data, api_key: API_KEY };  // Legger til api_key i body
      response = await fetch(url, {
        method,
        headers,
        body: JSON.stringify(bodyWithApiKey),
      });
    }

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      console.error(`Error: ${response.status} - ${response.statusText}`);
      return null;
    }
  } catch (error) {
    console.error(`Error: ${error.message}`);
    return null;
  }
};

function AdminPanel() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [fornavn, setFornavn] = useState('');
  const [etternavn, setEtternavn] = useState('');
  const [role, setRole] = useState('');
  const [brands, setBrands] = useState(''); // Nytt felt for brands
  const [users, setUsers] = useState([]);
  const [lockedUsers, setLockedUsers] = useState([]);
  const [frozenUsers, setFrozenUsers] = useState([]);
  const [message, setMessage] = useState('');

  // Separate variabler for å legge til ny bruker
  const [newUsername, setNewUsername] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newFornavn, setNewFornavn] = useState('');
  const [newEtternavn, setNewEtternavn] = useState('');
  const [newRole, setNewRole] = useState('');
  const [newBrands, setNewBrands] = useState(''); // Nytt felt for å legge til brands for ny bruker

  // Henter brukere når komponenten laster inn
  useEffect(() => {
    listUsers();
    listLockedOutUsers();
    listFrozenUsers();
  }, []);

  // Funksjon for å legge til bruker
  const addUser = async () => {
    const data = {
      username: newUsername,
      password: newPassword,
      fornavn: newFornavn,
      etternavn: newEtternavn,
      role: newRole,
      brands: newBrands.split(',').map(brand => brand.trim()), // Gjør kommaseparert streng om til en liste
    };
    const response = await sendAdminRequest('add_user', data);
    if (response) {
      setMessage(`Bruker ${newUsername} lagt til.`);
      listUsers(); // Oppdater listen etter å ha lagt til en bruker
      setNewUsername('');
      setNewPassword('');
      setNewFornavn('');
      setNewEtternavn('');
      setNewRole('');
      setNewBrands(''); // Tøm brands-feltet
    } else {
      setMessage('Feil ved oppretting av bruker.');
    }
  };

  // Funksjon for å hente brukere
  const listUsers = async () => {
    const response = await sendAdminRequest('list_users', {}, 'GET');
    if (response) {
      setUsers(response.users || []);
    } else {
      setMessage('Feil ved henting av brukere.');
    }
  };

// Funksjon for å hente låste brukere
const listLockedOutUsers = async () => {
  const response = await sendAdminRequest('locked_users', {}, 'POST');
  if (response) {
    setLockedUsers(response.locked_users || []);
  } else {
    setMessage('Feil ved henting av låste brukere.');
  }
};


  // Funksjon for å hente fryste brukere
  const listFrozenUsers = async () => {
    const response = await sendAdminRequest('frozen_users', {}, 'GET');
    if (response) {
      setFrozenUsers(response.frozen_users || []);
    } else {
      setMessage('Feil ved henting av fryste brukere.');
    }
  };

  // Funksjon for å fryse bruker
  const freezeUser = async () => {
    const data = { username };
    const response = await sendAdminRequest('freeze_user', data);
    if (response) {
      setMessage(`Bruker ${username} er nå frosset.`);
      listFrozenUsers(); // Oppdater listen over fryste brukere
    } else {
      setMessage('Feil ved frysing av bruker.');
    }
  };

  // Funksjon for å oppheve fryse-status
  const unfreezeUser = async () => {
    const data = { username };
    const response = await sendAdminRequest('unfreeze_user', data);
    if (response) {
      setMessage(`Fryse-status opphevet for ${username}.`);
      listFrozenUsers(); // Oppdater listen over fryste brukere
    } else {
      setMessage('Feil ved oppheving av fryse-status.');
    }
  };

  // Funksjon for å sjekke fryse-status
  const checkFreezeStatus = async () => {
    const data = { username };
    const response = await sendAdminRequest('check_freeze', data);
    if (response && response.status === "success" && response.message.includes("is frozen")) {
      setMessage(`Brukeren ${username} er frosset.`);
    } else if (response && response.status === "success" && response.message.includes("is not frozen")) {
      setMessage(`Brukeren ${username} er ikke frosset.`);
    } else {
      setMessage('Feil ved sjekk av fryse-status.');
    }
  };

  // Funksjon for å tilbakestille lock_count
  const resetLockCount = async () => {
    const data = { username };
    const response = await sendAdminRequest('reset_lock_count', data);
    if (response) {
      setMessage(`Lock count tilbakestilt for ${username}.`);
      listLockedOutUsers(); // Oppdater listen over låste brukere
    } else {
      setMessage('Feil ved tilbakestilling av lock count.');
    }
  };

  // Funksjon for å slette bruker
  const deleteUser = async () => {
    const response = await sendAdminRequest('delete_user', { username });
    if (response) {
      setMessage(`Bruker ${username} ble slettet.`);
      listUsers(); // Oppdaterer brukerlisten etter sletting
    } else {
      setMessage('Feil ved sletting av bruker.');
    }
  };

  // Funksjon for å håndtere når en bruker blir valgt fra listen
  const handleUserSelect = (user) => {
    setUsername(user.username);
    setFornavn(user.fornavn);
    setEtternavn(user.etternavn);
    setRole(user.role);
  };

  // Innhold for TabView: en tab for brukerliste, en for låste brukere, og en for fryste brukere
  const tabLabels = ['USER LIST', 'LOCKED OUT', 'FROZEN'];
  const tabContents = [
    <UserList users={users} onSelectUser={handleUserSelect} sx={{ maxHeight: '300px', overflowY: 'auto' }} />,
    <UserList users={lockedUsers} onSelectUser={handleUserSelect} sx={{ maxHeight: '300px', overflowY: 'auto' }} />,
    <UserList users={frozenUsers} onSelectUser={handleUserSelect} sx={{ maxHeight: '300px', overflowY: 'auto' }} />,
  ];

  return (
    <Paper elevation={3} sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Admin Panel
      </Typography>

      <Typography color="textSecondary" gutterBottom>
        {message}
      </Typography>

      <Grid container spacing={2}>
        {/* Vis valgte bruker øverst og brukerliste ved siden av */}
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom>
            Current Selected User
          </Typography>
          <TextField
            fullWidth
            label="Brukernavn"
            value={username}
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            fullWidth
            label="Fornavn"
            value={fornavn}
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            fullWidth
            label="Etternavn"
            value={etternavn}
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            fullWidth
            label="Rolle"
            value={role}
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
          />

          {/* Brukeroperasjoner plassert rett under feltene */}
          <Grid container spacing={2} sx={{ marginTop: 2 }}>
            <Grid item xs={6} sm={6}>
              <Button variant="outlined" color="error" onClick={freezeUser} fullWidth>
                Fryse bruker
              </Button>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Button variant="outlined" color="success" onClick={unfreezeUser} fullWidth>
                Oppheve fryse-status
              </Button>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Button variant="contained" color="info" onClick={checkFreezeStatus} fullWidth>
                Sjekk fryse-status
              </Button>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Button variant="contained" color="warning" onClick={resetLockCount} fullWidth>
                Tilbakestill lock count
              </Button>
            </Grid>
            {/* Legger til Slett Bruker-knappen her */}
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="error"
                onClick={deleteUser}
                fullWidth
                sx={{ marginTop: 2 }}
              >
                Slett bruker
              </Button>
            </Grid>
          </Grid>

          {/* Seksjon for å legge til ny bruker */}
          <Typography variant="h6" sx={{ marginTop: 3 }}>Legg til ny bruker</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Brukernavn"
                value={newUsername}
                onChange={(e) => setNewUsername(e.target.value)}
                margin="normal"
              />
              <TextField
                fullWidth
                type="password"
                label="Passord"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Fornavn"
                value={newFornavn}
                onChange={(e) => setNewFornavn(e.target.value)}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Etternavn"
                value={newEtternavn}
                onChange={(e) => setNewEtternavn(e.target.value)}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Rolle"
                value={newRole}
                onChange={(e) => setNewRole(e.target.value)}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Brands (comma-separated)"
                value={newBrands}
                onChange={(e) => setNewBrands(e.target.value)}
                margin="normal"
              />
              <Button
                variant="contained"
                color="primary"
                onClick={addUser}
                fullWidth
                sx={{ marginTop: 2 }}
              >
                Legg til bruker
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {/* TabView for brukerliste, fryste brukere og låste brukere */}
        <Grid item xs={12} sm={6}>
          <TabView tabLabels={tabLabels} tabContents={tabContents} variant="small" />
        </Grid>
      </Grid>

      <Divider sx={{ marginY: 3 }} />
    </Paper>
  );
}

export default AdminPanel;

import React from 'react';
import { List, ListItem, ListItemText, Paper, Box } from '@mui/material';

const UserList = ({ users, onSelectUser }) => {
  return (
    <Paper sx={{ maxHeight: 300, overflow: 'auto', padding: 1 }}>
      <List>
        {users.map((user) => (
          <ListItem
            key={user.username}
            button
            onClick={() => onSelectUser(user)} // Kaller påSelectUser-funksjonen når en bruker trykkes på
            sx={{
              marginBottom: '10px',
              borderRadius: '8px',
              backgroundColor: '#2e2e2e',
              padding: '16px',
              '&:hover': {
                backgroundColor: '#565656',
              },
            }}
          >
            <ListItemText
              primary={`${user.username} - ${user.role}`}
              secondary={`Fornavn: ${user.fornavn}, Etternavn: ${user.etternavn}, Freeze: ${user.freeze}, Lock Count: ${user.lock_count}`}
              primaryTypographyProps={{ fontWeight: 'bold' }}
            />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export default UserList;

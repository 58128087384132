import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function TabPanel({ children, value, index, variant, customSmallDimensions }) {
  const defaultSmallStyle = {
    p: 1,
    border: '1px solid #3a3a3a',
    borderRadius: '4px',
    backgroundColor: '#252526',
    color: '#fff',
    mt: 1,
    width: '100%',
    margin: '0px',
    minHeight: 'calc(100vh - 400px)', // Default small height
    maxHeight: 'calc(100vh - 300px)', // Default small max height
    boxSizing: 'border-box',
    overflowY: 'auto',
  };

  const panelStyle = variant === 'small'
    ? { ...defaultSmallStyle, ...customSmallDimensions } // Override with custom dimensions if provided
    : {
        p: 2,
        border: '2px solid #3a3a3a',
        borderRadius: '8px',
        backgroundColor: '#252526',
        color: '#fff',
        mt: 0,
        width: '100%',
        margin: '8px',
        minHeight: '100%',
        marginBottom: '60px',
        boxSizing: 'border-box',
        overflowY: 'auto',
      };

  return (
    <div hidden={value !== index}>
      {value === index && <Box sx={panelStyle}>{children}</Box>}
    </div>
  );
}

export default function TabView({ tabLabels = [], tabContents = [], variant = 'normal', customSmallDimensions }) {
  const query = useQuery();
  const navigate = useNavigate();

  // Hent initiale verdier for tab og subTab fra URL-en, eller sett standard til første verdi
  const initialTab = query.get('tab') || (tabLabels.length > 0 ? tabLabels[0].toLowerCase() : '');
  const initialSubTab = query.get('subTab') || (tabLabels.length > 0 ? tabLabels[0].toLowerCase() : '');

  const [value, setValue] = useState(initialTab); // Hovedfane
  const [subValue, setSubValue] = useState(initialSubTab); // Underfane

  // Oppdater hovedfane når URL-en endrer seg
  useEffect(() => {
    const queryTab = query.get('tab');
    if (queryTab && queryTab !== value) {
      setValue(queryTab);
    }
  }, [query, value]);

  // Oppdater underfane når URL-en endrer seg
  useEffect(() => {
    const querySubTab = query.get('subTab');
    if (querySubTab && querySubTab !== subValue) {
      setSubValue(querySubTab);
    } else if (!querySubTab && variant === 'small' && tabLabels.length > 0) {
      // Hvis ingen subTab er angitt, sett den første subTab
      const updatedParams = new URLSearchParams(window.location.search);
      updatedParams.set('subTab', tabLabels[0].toLowerCase());
      navigate(`?${updatedParams.toString()}`);
      setSubValue(tabLabels[0].toLowerCase());
    }
  }, [query, subValue, tabLabels, navigate, variant]);

  const handleTabChange = (tabName) => {
    const updatedParams = new URLSearchParams(window.location.search);
    updatedParams.set('tab', tabName.toLowerCase());

    // Fjern underfane når vi bytter til en ny hovedfane
    updatedParams.delete('subTab');

    navigate(`?${updatedParams.toString()}`);
    setValue(tabName.toLowerCase());
    setSubValue(''); // Tilbakestill underfane når du bytter hovedfane
  };

  const handleSubTabChange = (subTabName) => {
    const updatedParams = new URLSearchParams(window.location.search);
    updatedParams.set('subTab', subTabName.toLowerCase());
    navigate(`?${updatedParams.toString()}`);
    setSubValue(subTabName.toLowerCase());
  };

  const tabStyle = variant === 'small'
    ? {
        width: '140px',
        height: '40px',
        margin: '5px',
        marginTop: '-5px',
        marginBottom: '5px',
      }
    : {
        width: '180px',
        height: '80px',
        margin: '6px',
        marginBottom: '-15px',
      };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          gap: variant === 'small' ? 0 : 0,
          mb: variant === 'small' ? 0 : 2,
          paddingLeft: '2px',
          paddingRight: '8px',
          paddingBottom: '0px',
          paddingTop: '8px',
          boxSizing: 'border-box',
        }}
      >
        {tabLabels.map((label, index) => (
          <Box
            key={index}
            onClick={() => variant === 'small' ? handleSubTabChange(label.toLowerCase()) : handleTabChange(label.toLowerCase())}
            sx={{
              ...tabStyle,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              borderRadius: variant === 'small' ? '4px' : '8px',
              boxShadow: (variant === 'small' ? subValue : value) === label.toLowerCase() ? '0px 4px 12px rgba(0, 0, 0, 0.1)' : 'none',
              backgroundColor:
                (variant === 'small' ? subValue : value) === label.toLowerCase()
                  ? variant === 'small'
                    ? '#e9531f'
                    : '#1677ff'
                  : variant === 'small'
                  ? '#69250d'
                  : '#303031',
              color: (variant === 'small' ? subValue : value) === label.toLowerCase() ? '#fff' : '#c5c5c5',
              cursor: 'pointer',
              transition: 'background-color 0.2s, transform 0.1s, box-shadow 0.1s',
              '&:hover': {
                backgroundColor:
                  (variant === 'small' ? subValue : value) === label.toLowerCase()
                    ? variant === 'small'
                      ? '#8c2e10'
                      : '#1677ff'
                    : variant === 'small'
                    ? '#a53713'
                    : '#3b3b3b',
                color: '#fff',
                transform: 'scale(1.02)',
                boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.15)',
              },
              '&:active': {
                transform: 'scale(0.95)',
              },
            }}
          >
            <Typography variant="body1" fontWeight={(variant === 'small' ? subValue : value) === label.toLowerCase() ? 'bold' : 'normal'}>
              {label}
            </Typography>
          </Box>
        ))}
      </Box>

      {tabContents.map((content, index) => (
        <TabPanel key={index} value={variant === 'small' ? subValue : value} index={tabLabels[index].toLowerCase()} variant={variant} customSmallDimensions={customSmallDimensions}>
          {content}
        </TabPanel>
      ))}
    </Box>
  );
}

import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from './AuthContext'; // For å få tilgang til brukerens rolle
import TabView from '../components/shared/TabView'; // Importer TabView-komponenten
import Cookies from 'js-cookie';

function Shipping() {
	const { userRole } = useContext(AuthContext); // Hent brukerens rolle fra AuthContext
	const [tabLabels, setTabLabels] = useState(['SENDERELLA']); // Standardfaner for forsendelser
	const [tabContents, setTabContents] = useState([<div>Innhold omgående Forsendelser</div>]); // Innhold for faner
	const [brands, setBrands] = useState([]);

	// Hent brands fra cookie
	useEffect(() => {
		const storedBrands = Cookies.get('brands');
		if (storedBrands) {
			try {
				const decodedBrands = decodeURIComponent(storedBrands)
					.replace(/["[\]]/g, '')
					.split(',');
				setBrands(decodedBrands);
			} catch (error) {
				console.error('Error decoding brands from cookie:', error);
			}
		}
	}, []);

	// Oppdater fanene basert på brands
	useEffect(() => {
		if (brands.length > 0) {
			const newTabLabels = [...tabLabels]; // Kopierer eksisterende tabLabels
			const newTabContents = [...tabContents]; // Kopierer eksisterende tabContents

			if (brands.includes('apple')) {
				newTabLabels.push('APPLE');
				newTabContents.push(<div>Apple Forsendelser</div>);
			}

			if (brands.includes('samsung')) {
				newTabLabels.push('SAMSUNG');
				newTabContents.push(<div>Samsung Forsendelser</div>);
			}

			setTabLabels(newTabLabels);
			setTabContents(newTabContents);
		}
	}, [brands]);

	return (
		<div>
			<h1>Shipments</h1>
			<p></p>
			{tabLabels.length > 0 && tabContents.length > 0 ? (
				<TabView tabLabels={tabLabels} tabContents={tabContents} /> // Bruker TabView-komponenten
			) : (
				<div>Ingen faner tilgjengelig</div>
			)}
		</div>
	);
}

export default Shipping;
import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from './AuthContext'; // For å få tilgang til brukerens rolle
import TabView from '../components/shared/TabView'; // Importer TabView-komponenten
import Cookies from 'js-cookie';

function Stock() {
	const { userRole } = useContext(AuthContext); // Hent brukerens rolle fra AuthContext
	const [tabLabels, setTabLabels] = useState(['LAGER OVERSIKT']); // Standardfaner
	const [tabContents, setTabContents] = useState([<div>LOCAL STOCK</div>]); // Innhold for faner
	const [brands, setBrands] = useState([]);

	// Hent brands fra cookie
	useEffect(() => {
		const storedBrands = Cookies.get('brands');
		if (storedBrands) {
			try {
				const decodedBrands = decodeURIComponent(storedBrands)
					.replace(/["[\]]/g, '')
					.split(',');
				setBrands(decodedBrands);
			} catch (error) {
				console.error('Error decoding brands from cookie:', error);
			}
		}
	}, []);

	// Oppdater fanene basert på brands
	useEffect(() => {
		if (brands.length > 0) {
			const newTabLabels = ['LAGER OVERSIKT']; // Reset labels
			const newTabContents = [<div>Local stock</div>]; // Reset contents

			if (brands.includes('apple')) {
				newTabLabels.push('APPLE');
				newTabContents.push(<div>Apple Lager</div>);
			}

			if (brands.includes('samsung')) {
				newTabLabels.push('SAMSUNG');
				newTabContents.push(<div>Samsung Lager</div>);
			}

			setTabLabels(newTabLabels);
			setTabContents(newTabContents);
		}
	}, [brands]);

	return (
		<div>
			<h1>Stock</h1>
			<p>Her kan du administrere lager, reservedeler, og forsendelser.</p>
			{tabLabels.length > 0 && tabContents.length > 0 && tabLabels.every(Boolean) && tabContents.every(Boolean) ? (
				<TabView tabLabels={tabLabels} tabContents={tabContents} /> // Bruker TabView-komponenten
			) : (
				<div>Ingen faner tilgjengelig</div>
			)}
		</div>
	);
}

export default Stock;

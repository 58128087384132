import React, { useState, useContext } from 'react';
import BomListManagerSearchBar from './BomListManager/BomListSearchBar';
import BomListManagerFilterBox from './BomListManager/BomListManagerFilterBox'; // Importer filterboksen
import BomListManagerGrid from './BomListManager/BomListManagerGrid'; // Importer grid-komponenten
import { AuthContext } from '../AuthContext';
import Cookies from 'js-cookie';

function BomListManager() {
  const { isAuthenticated } = useContext(AuthContext); // Bruk AuthContext for å sjekke om brukeren er autentisert
  const [filters, setFilters] = useState({}); // Legg til state for søkefiltre
  const [rows, setRows] = useState([]); // State for å lagre rader med data
  const [loading, setLoading] = useState(false); // Loading state

  const [boxTitles, setBoxTitles] = useState({
    box1: '',
    box2: '',
    box3: '',
    box4: '',
    box5: '',
    box6: '',
    box7: '',
    box8: '',
  });

  // Funksjon for å oppdatere titler basert på filterboks input
  const handleBoxTitleChange = (box, title) => {
    const updatedTitle = title.replace('#', ''); // Fjern '#'
    setBoxTitles((prevTitles) => ({
      ...prevTitles,
      [box]: updatedTitle,
    }));
  };

  // Definer kolonnene til tabellen
  const columns = [
    { headerName: 'Part Number', field: 'partNumber', width: '150px', minWidth: '100px' },
    { headerName: 'Description', field: 'description', width: '300px', minWidth: '200px' },
    { headerName: 'Price', field: 'price', width: '100px', minWidth: '80px' },
    { headerName: 'Availability', field: 'availability', width: '120px', minWidth: '100px' },
    { headerName: 'Valid From', field: 'validFrom', width: '130px', minWidth: '100px' },
  ];

  // Funksjon for å hente data fra Flask-endepunktet
  const fetchBomInfo = async (modelCode, serialNo = '') => { // Standardverdi for serialNo er en tom streng
    setLoading(true); // Sett loading til true når forespørselen starter
  
    try {
      const api_url = process.env.REACT_APP_STATUS_API_URL;
  
      // Hent JWT-token fra cookie
      const token = Cookies.get('token'); // Vi antar at cookie heter 'token'
  
      if (!token) {
        console.error("Ingen token funnet. Du må være innlogget for å hente BOM info.");
        setLoading(false);
        return;
      }
  
      // Lag request body med modelCode og optional serialNo
      const requestBody = {
        model_code: modelCode,
        serial_no: serialNo || '', // Send tom streng hvis serialNo ikke er oppgitt
      };
  
      const response = await fetch(`${api_url}/bom-info`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Legg til JWT-token i Authorization-headeren
        },
        body: JSON.stringify(requestBody),
        credentials: 'include', // Sørg for å sende cookies med forespørselen
      });
  
      const data = await response.json();
      console.log("Response fra API:", data);  // Debug logg
  
      if (response.ok) {
        setRows(data.bom_info || []); // Lagre JSON-data i rows
      } else {
        console.error('Error fetching BOM info:', data.error);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false); // Sett loading til false når forespørselen er ferdig
    }
  };

  // Funksjon for håndtering av søk
  const handleSearch = async (searchFilters) => {
    const { modelCode, serialNo } = searchFilters;
  
    if (!modelCode) {
      console.error('Model code is required.');
      return;
    }
  
    console.log('Søker med følgende filtre:', {
      modelCode,
      serialNo: serialNo || '', // Bruk tom streng hvis serialNo ikke er angitt
    });
  
    // Kall fetchBomInfo med modelCode og optional serialNo
    await fetchBomInfo(modelCode, serialNo || '');
  };

  // Sjekk om brukeren er autentisert før vi viser komponentene
  if (!isAuthenticated) {
    console.log('User not authenticated'); // DEBUG: Print hvis brukeren ikke er autentisert
    return <div>Vennligst logg inn for å bruke BOM List Manager.</div>;
  }

  return (
    <div className="BomListManager">
      {/* Søkebar */}
      <BomListManagerSearchBar onSearch={handleSearch} />

      {/* Loading spinner */}
      {loading && <div>Loading...</div>}

      {/* Grid med 8 tabeller */}
      <BomListManagerGrid columns={columns} rows={rows} boxTitles={boxTitles} />
    </div>
  );
}

export default BomListManager;

// AnalogWatch.js
import React, { useRef, useEffect } from 'react';

const AnalogWatch = () => {
	const canvasRef = useRef(null);

	useEffect(() => {
		const canvas = canvasRef.current;
		const context = canvas.getContext('2d');

		// Sett eksplisitt bredde og høyde
		canvas.width = 200;
		canvas.height = 200;
		
		const radius = canvas.width / 2;
		context.translate(radius, radius); // Flytter 0,0 til senter av klokken

		const drawClock = () => {
			context.clearRect(-radius, -radius, canvas.width, canvas.height); // Rydder lerretet før tegning
			drawFace(context, radius);
			drawNumbers(context, radius);
			drawTime(context, radius);
		};

		const intervalId = setInterval(drawClock, 1000);
		return () => clearInterval(intervalId);
	}, []);

	const drawFace = (ctx, radius) => {
		ctx.beginPath();
		ctx.arc(0, 0, radius * 0.9, 0, 2 * Math.PI); // Justerer radius
		ctx.fillStyle = '#3a3a3a';
		ctx.fill();

		ctx.strokeStyle = '#f5f5f5';
		ctx.lineWidth = radius * 0.05;
		ctx.stroke();

		ctx.beginPath();
		ctx.arc(0, 0, radius * 0.1, 0, 2 * Math.PI);
		ctx.fillStyle = '#f5f5f5';
		ctx.fill();
	};

	const drawNumbers = (ctx, radius) => {
		ctx.font = `${radius * 0.15}px Arial`;
		ctx.textBaseline = 'middle';
		ctx.textAlign = 'center';
		ctx.fillStyle = '#f5f5f5';

		for (let num = 1; num <= 12; num++) {
			const ang = (num * Math.PI) / 6;
			ctx.rotate(ang);
			ctx.translate(0, -radius * 0.75);
			ctx.rotate(-ang);
			ctx.fillText(num.toString(), 0, 0);
			ctx.rotate(ang);
			ctx.translate(0, radius * 0.75);
			ctx.rotate(-ang);
		}
	};

	const drawTime = (ctx, radius) => {
		const now = new Date();
		let hour = now.getHours();
		let minute = now.getMinutes();
		let second = now.getSeconds();

		hour = hour % 12;
		hour = (hour * Math.PI) / 6 + (minute * Math.PI) / (6 * 60) + (second * Math.PI) / (360 * 60);
		drawHand(ctx, hour, radius * 0.5, radius * 0.07);

		minute = (minute * Math.PI) / 30 + (second * Math.PI) / (30 * 60);
		drawHand(ctx, minute, radius * 0.8, radius * 0.07);

		second = (second * Math.PI) / 30;
		drawHand(ctx, second, radius * 0.9, radius * 0.02);
	};

	const drawHand = (ctx, pos, length, width) => {
		ctx.beginPath();
		ctx.lineWidth = width;
		ctx.lineCap = 'round';
		ctx.moveTo(0, 0);
		ctx.rotate(pos);
		ctx.lineTo(0, -length);
		ctx.stroke();
		ctx.rotate(-pos);
	};

	return (
		<div style={styles.container}>
			<canvas ref={canvasRef} style={styles.canvas}></canvas>
		</div>
	);
};

const styles = {
	container: {
		backgroundColor: '#3a3a3a', // Mørk bakgrunn for å passe inn i temaet
		padding: '20px',
		borderRadius: '8px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
	},
	canvas: {
		backgroundColor: '#3a3a3a', // Mørk bakgrunn for å matche stilen
		borderRadius: '50%',
		border: '2px solid #f5f5f5'
	}
};

export default AnalogWatch;

// src/components/ProtectedRoute.js
import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

function ProtectedRoute({ children }) {
  const { isAuthenticated } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1); // 0.5 sek forsinkelse for å sikre at cookien lastes inn
  }, []);

  if (isLoading) {
    return <div>Loading...</div>; // Kan byttes ut med en spinner for bedre UX
  }

/*   if (!isAuthenticated) {
    return <Navigate to="/login" />;
  } */

  return children;
}

export default ProtectedRoute;

import React from 'react';
import { Box, Typography, Divider } from '@mui/material';

const ActivityDetails = ({ activity }) => {
  const renderObject = (obj, parentKey = '') => {
    return Object.keys(obj).map((key) => {
      const value = obj[key];
      const formattedKey = key.split('.').pop();  // Bruker kun siste del av nøkkelen

      if (typeof value === 'object' && value !== null) {
        return (
          <Box key={key} sx={{ marginBottom: '10px' }}>
            <Typography variant="h6" sx={{ color: '#e9531f', fontWeight: 'bold', marginBottom: '8px' }}>
              {formattedKey}:
            </Typography>
            {renderObject(value)}
          </Box>
        );
      } else {
        return (
          <Box key={key} sx={{ display: 'grid', gridTemplateColumns: '150px 1fr', gap: '20px', marginBottom: '8px' }}>
            <Typography variant="body2" sx={{ fontWeight: 'bold', color: '#e9531f' }}>
              {formattedKey}:
            </Typography>
            <Typography variant="body2" sx={{ color: '#ffffff' }}> {/* Hvit tekst for verdiene */}
              {value ? value.toString() : 'N/A'}
            </Typography>
          </Box>
        );
      }
    });
  };

  return (
    <Box
      sx={{
        padding: '16px',
        backgroundColor: '#1c1c1c',
        borderRadius: '8px',
        boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
        maxWidth: '1200px', // Gir god bredde til venstrejustering
        margin: '20px 0',
      }}
    >
      <Typography variant="h5" sx={{ marginBottom: '16px', color: '#e9531f' }}>
        Activity Details
      </Typography>
      <Divider sx={{ marginBottom: '16px', borderColor: '#777' }} />
      {/* Eksempler på seksjonsinndeling */}
      <Box sx={{ marginBottom: '20px' }}>
        <Typography variant="h6" sx={{ color: '#e9531f', fontWeight: 'bold', marginBottom: '8px' }}>
          Consumer Information
        </Typography>
        {renderObject(activity.consumer)}
      </Box>
      <Divider sx={{ marginBottom: '16px', borderColor: '#444' }} />

      <Box sx={{ marginBottom: '20px' }}>
        <Typography variant="h6" sx={{ color: '#e9531f', fontWeight: 'bold', marginBottom: '8px' }}>
          Customer Information
        </Typography>
        {renderObject(activity.customer)}
      </Box>
      <Divider sx={{ marginBottom: '16px', borderColor: '#444' }} />

      <Box sx={{ marginBottom: '20px' }}>
        <Typography variant="h6" sx={{ color: '#e9531f', fontWeight: 'bold', marginBottom: '8px' }}>
          Product Data
        </Typography>
        {renderObject(activity.productData)}
      </Box>
      <Divider sx={{ marginBottom: '16px', borderColor: '#444' }} />

      <Box sx={{ marginBottom: '20px' }}>
        <Typography variant="h6" sx={{ color: '#e9531f', fontWeight: 'bold', marginBottom: '8px' }}>
          Order Data
        </Typography>
        {renderObject(activity.orderData)}
      </Box>
      <Divider sx={{ marginBottom: '16px', borderColor: '#444' }} />
    </Box>
  );
};

export default ActivityDetails;

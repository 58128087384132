import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Oppdater state slik at neste render viser fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Du kan logge feilen til en ekstern feillogg her
    console.log("ErrorBoundary caught an error", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Du kan rendere fallback-UI her
      return <h1>Something unexpected happened.</h1>;
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
